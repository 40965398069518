<template>
    <nav class="navigation scroll-bar">
        <div class="container pl-0 pr-0">
            <div class="nav-content">
                <div class="nav-top ml-3">
                    <router-link to="/index">
                        <img src="images/logo1.png" alt="logo" class="w-75">
                    </router-link>
                    <a href="#" class="close-nav d-inline-block d-lg-none">
                        <i class="ti-close bg-grey mb-4 btn-round-sm font-xssss fw-700 text-dark ml-auto mr-2 "></i>
                    </a>
                </div>
                <!--<div class="nav-caption fw-600 font-xssss text-grey-500"><span>New </span>Feeds</div>-->
                <ul class="mb-3">
                    <li class="logo d-none d-xl-block d-lg-block"></li>
                    <li>
                        
                        <router-link to="/main" :class ="tools" class=" nav-content-bttn open-font" data-tab="tools" @click.native="onMenuClick('tools')">
                            <i class="feather-grid mr-3"></i>
                            <span>AI创意工具</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="javascript:void(0)" :class ="bot" class="nav-content-bttn open-font" data-tab="bot" @click.native="onMenuClick('bot')">
                            <i class="feather-life-buoy mr-3"></i>
                            <span>AI聊天机器人</span>
                       
                        </a>
                    </li>
                    <li>
                        <router-link to="/history" :class ="history" class="nav-content-bttn open-font" data-tab="history" @click.native="onMenuClick('history')">
                            <i class="feather-clock mr-3"></i>
                            <span>历史记录</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/classroom" :class ="create" class="nav-content-bttn open-font" data-tab="history" @click.native="onMenuClick('create')">
                            <i class="feather-book-open mr-3"></i>
                            <span>我的班级</span>
                        </router-link>
                        
                       
                    </li>
                </ul>
                <div class="nav-caption fw-600 font-xssss text-grey-500">
                    <span></span> 设置
                </div>
                <ul class="mb-3">
                    <li class="logo d-none d-xl-block d-lg-block"></li>
                    <li>
                        <router-link to="/userinfo" class="nav-content-bttn open-font h-auto pt-2 pb-2"  @click.native="onMenuClick('other')">
                            <i class="font-sm feather-settings mr-3 text-grey-500"></i>
                            <span>个人资料设置</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/upgrade" class="nav-content-bttn open-font h-auto pt-2 pb-2"  @click.native="onMenuClick('other')">
                            <i class="font-sm feather-arrow-up-circle mr-3 text-grey-500"></i>
                            <span>升级</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="logout()" class="nav-content-bttn open-font h-auto pt-2 pb-2">
                            <i class="font-sm feather-log-out mr-3 text-grey-500"></i>
                            <span>退出</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</template>
<script>
import { api1, api2 } from '@/api';
import store from '@/store';
export default {
    name: 'LeftMenu',
    //alert(this.$route.path);
    data() {
        return {
            tools: 'active',
            bot: '',
            history: '',
            create: '',
           
        }
    },
   
    methods: {
        onMenuClick(message,event) {

            // 在这里可以执行你的额外操作
            //console.log(message);
            if(message == 'tools'){
                this.tools = 'active';
                this.bot = '';
                this.history='';
                this.create='';
            }else if(message == 'bot'){
                this.tools = '';
                this.bot = 'active';
                this.history='';
                this.create='';
                this.getMsg();
            }else if(message == 'history'){
                this.tools = '';
                this.bot = '';
                this.history='active';
                this.create='';
            }else if(message == 'create'){
                this.tools = '';
                this.bot = '';
                this.history='';
                this.create='active';
            
            }else{
                this.tools = '';
                this.bot = '';
                this.history='';
                this.create='';
            }

        },
        logout(){
            this.$confirm('确认退出吗?', '提示', 
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'bg-current',
                type: 'warning'
            }).then(() => {
                //this.$alert('退出成功');
                store.commit('clearAccessToken');
                store.commit('clearRefreshToken');
                this.$router.push('/login');
            }).catch(() => {
                // 点击取消执行的逻辑
                //this.$alert('操作已取消', '提示');
            });
        },
        getMsg(){
            api1.get("/message/msg_findByBot",{
                params:{
                    bot_id:'7416559272533852187', 
                    user_key:store.state.access_token,
                }
            }).then(res=>{
                console.log("获取消息数据成功！");
                console.log(res.data);
                if(res.data.length>0){
                    this.$router.push({ // 跳转到指定页面，
                        name: 'aibot',
                        query: {
                            con_id: res.data[0].conversation_id,
                            bot_id: '7416559272533852187'
                        }
                    
                    });
                }else{
                    this.createConveration();
                }
            }).catch(error => {
                console.log("获取消息数据出错！")
                console.error(error);
            });
        },
        createConveration(){
            api2.post('/v1/conversation/create').then(res=>{
                if(res.data.code == 0){
                    console.log("创建会话成功！"+res.data.data);
                    let conversation_id = res.data.data.id;
                    let created_at = res.data.data.created_at;
                    this.sendMsg(conversation_id,created_at);
                }else{
                    console.log("调用coze创建会话错误:"+res.data.msg);
                }
            });
        },
        sendMsg(conversation_id,created_at){
            const allmsg = "";
            let params = new URLSearchParams();
            params.append("conversation_id",conversation_id);
            params.append("bot_id","7416559272533852187");
            params.append("chat_id","");
            params.append("message_id","");
            params.append("content",allmsg);
            params.append("created_date",created_at);
            params.append("type","user");
            params.append("toolid",37);
            api1.post('/message/msg_insert?user_key='+store.state.access_token, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }}
            ).then(res=>{
                if (res.status === 200 && res.data !== '' && res.data !== null && res.data !== "failed") {
                    //console.info(res);
                    this.$router.push({ // 跳转到指定页面，
                        name: 'aibot',
                        query: {    
                            con_id: conversation_id,
                            bot_id: '7416559272533852187'
                        }
                    
                    });
                }
            }).catch(error => {
                console.log("生成用户消息数据出错！")
                console.error(error);
            });
        },
       

    }
}
</script>