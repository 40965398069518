import { createApp } from 'vue';
import App from './App.vue';

import { api1 } from './api';
import router from './router';
import store from './store';


import ElementUI from 'element-plus'; // 引入element-ui
import 'element-plus/dist/index.css'; // element-ui的css样式要单独引入

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import axios from 'axios';
import VueQrcode from 'vue-qrcode';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});


const app = createApp(App);
router.beforeEach((to, from, next) => {
    store.commit('getAccessToken');
    const token = store.state.access_token;

    /**if (!token && to.name !== 'login') {
        console.log('未登录'+token);
        if(to.name === 'register'){
            next({ name: 'register' });
        }else{
            console.log('BBB');
            next({ name: 'login' });
        }
    } else if (token && to.name === 'login') {
        next({ name: 'main' });
    } else (
        next()
    )*/
    if(to.name !== 'login' && to.name !== 'register' && to.name !== 'index' && to.name !== 'home'){
        if(!token){
            next({ name: 'login' });
        }else{
            next();
        }
    }else{
        if(token){
            if(to.name === 'index'){
                next();
            }else{
                next({ name: 'main' });
            }
           
        }else{
            next();
        }

    }
})

//定义全局函数
async function saveMessage(bot_id,conversation_id,chat_id,message_id,created_at,allmsg,toolid,type,files) {

    // 函数内容
       // console.log(allmsg+"--"+created_at);
        let params = new URLSearchParams();
        let returnvalue=false;
        params.append("conversation_id",conversation_id);
        params.append("bot_id",bot_id);
        params.append("chat_id",chat_id);
        params.append("message_id",message_id);
        params.append("content",allmsg);
        params.append("type",type);
        params.append("created_date",created_at);
        params.append("toolid",toolid);
        params.append("files",files);
        await api1.post('/message/msg_insert?user_key='+store.state.access_token, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }}
        ).then(res=>{
            if (res.status === 200 && res.data !== '' && res.data !== null && res.data !== "failed") {
                //console.log("111111")
                returnvalue = res.data;
                
            }else{
                returnvalue = "failed";
            }
        }).catch(error => {
            console.log("生成用户消息数据出错！")
            console.error(error);
            returnvalue = "failed";
        });
        return returnvalue;
};

//app.config.globalProperties.$saveMessage = saveMessage;
app.config.globalProperties.$proxy= axios;
app.config.globalProperties.$imgurl= "D:/ai/ai_stu/public/images/headimg";
app.config.productionTip = false;
app.component('VueDatePicker', VueDatePicker);
app.component('vue-qrcode', VueQrcode)
//const globalProperties = {
//    $imgurl: "D:/ai/ai_stu/public/images/headimg",
//    $proxy: axios,
 //   $saveMessage: saveMessage,
//}

app.provide('$saveMessage', saveMessage);

app.use(store).use(router).use(ElementUI).use(VMdPreview).mount('#app')

//app.config.globalProperties.$coze_key= "pat_NppAVWypzpcvztl5qdviDPFAOoDM1DYiF1UkeSUJo3Kb5PPOF7niDNSKzbnj9ER4";
//let VUE_APP_BASE_DEV_API = 'http://localhost:8087';
//let VUE_APP_BASE_COZE_API = "http://localhost:8092"


//设置请求url
//axios.defaults.baseURL = VUE_APP_BASE_DEV_API;
//设请求头
//axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
//
////axios.defaults.headers.post['Accept'] = 'application/json'

