import axios from 'axios';
const api1 = axios.create({
    //baseURL: process.env.VUE_APP_API1_URL || 'https://localhost:8087',
    baseURL: process.env.VUE_APP_API1_URL || 'https://www.asocool.cn:8087',
    timeout: 5000,
    headers: {'Content-Type': 'application/json'}
  });
  
  const api2 = axios.create({
    baseURL: process.env.VUE_APP_API2_URL || 'https://api.coze.cn',
    timeout: 20000,
    headers: {'Content-Type': 'application/json',
              //'Authorization' : 'Bearer pat_NppAVWypzpcvztl5qdviDPFAOoDM1DYiF1UkeSUJo3Kb5PPOF7niDNSKzbnj9ER4'
              'Authorization' : 'Bearer pat_N0tDeUzT0vIMyUjfqze5jCaGj2LQh09H4gkJ9J4PcsI7U30Low8Lvck5DPPhRjxj'
    }
  });
  
  export { api1, api2 };
