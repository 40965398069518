import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Portal.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/MainView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/Message.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/History.vue')
  },
  {
    path: '/classroom',
    name: 'classroom',
    component: () => import('../views/ClassRoom.vue')
  },
  {
    path: '/addclassroom1',
    name: 'addclassroom1',
    component: () => import('../views/AddClassRoom1.vue')
  },
  {
    path: '/addclassroom2',
    name: 'addclassroom2',
    component: () => import('../views/AddClassRoom2.vue')
  },
  {
    path: '/addclassroom3',
    name: 'addclassroom3',
    component: () => import('../views/AddClassRoom3.vue')
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('../views/UserInfo.vue')
  }
  ,
  {
    path: '/upgrade',
    name: 'upgrade',
    component: () => import('../views/upgrade.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/aibot',
    name: 'aibot',
    component: () => import('../views/AiBot.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Portal.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/JoinClass.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
