<template>
  <div class="preloader"></div>
  <div class="main-wrapper">
      <!-- 左侧菜单 -->
      <Leftmenu v-if="showLeftmenu"/>
      <!-- 左侧菜单 -->
      <!-- main content -->
      <router-view/>
      <!-- main content -->
  </div>
  
</template>
<script>
import Leftmenu from '@/components/LeftMenu.vue';

export default {
    name: 'appview',
    components: {
        Leftmenu
    },
    data() {
        return {
            showLeftmenu: true // 控制 navbar 是否显示
        };
    },
    watch:{
    // 监听路由发生改变
        '$route':{
            handler(newVal) {
                if(newVal.path == '/login' || newVal.path == '/register' || newVal.path == '/index' || newVal.path == '/'){
                    console.info(222);
                    this.showLeftmenu = false;
                }else{
                    console.info(111);
                    this.showLeftmenu = true;
                }
            }
        }
    }
}
</script>
